#wrap{
    max-height:100vh;
    
}

table {
    margin-left: auto;
    margin-right: auto;
    
  }


tr th.topLeft{

    top: 0;
    left: 0;
    min-width: 25px;
    position: sticky;
    position: -webkit-sticky;
    z-index: 99;
    padding: 0px;
    background-color: lightblue;
    text-align: left;
  }

tr th.top{
    top: 0;
    min-width: 75px;
    position: sticky;
    position: -webkit-sticky;
    z-index: 6;
    padding: 0px;
    background-color: lightblue;
    text-align: left;
}

tr th.row{
    left: 0;
    min-width: 25px;
    position: sticky;
    position: -webkit-sticky;
    z-index: 6;
    padding: 0px;
    text-align: left;

}

@media (max-width:400px) {
    #wrap{
        overflow-x: scroll;/*only for mobile*/
        overflow-y: scroll;/*only for mobile*/
    }
}